import React from "react"

import Container from "../components/Container/Container"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>404: NOT FOUND</h1>
      <p>Tej strony nie ma (jeszcze?)</p>
    </Container>
  </Layout>
)

export default NotFoundPage
